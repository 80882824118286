import type { SVGProps } from "react";
import * as React from "react";

interface SvgGridStrokeProps extends SVGProps<SVGSVGElement> {
  color?: string;
}
const SvgFacebook = ({ color = "#1CAB9F", ...props }: SvgGridStrokeProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    fill="currentColor"
    {...props}
    strokeWidth={0.1}
  >
    <path d="M24 12a12 12 0 1 0-13.875 11.855V15.47H7.079v-3.468h3.046V9.357c0-3.008 1.792-4.669 4.532-4.669q1.352.019 2.687.234v2.954H15.83a1.734 1.734 0 0 0-1.955 1.875V12h3.328l-.532 3.468h-2.796v8.386A12 12 0 0 0 24 12.001" />
  </svg>
);
export default SvgFacebook;
