import type { SVGProps } from "react";
import * as React from "react";

interface SvgGridStrokeProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const SvgGridStroke = ({ color = "black", ...props }: SvgGridStrokeProps) => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g
      stroke={color}
      fill={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.1}
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M3 2a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm1 7V4h5v5zm10-7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm1 7V4h5v5zm-2 5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1zm2 1v5h5v-5zM3 13a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1zm1 7v-5h5v5z" />
    </g>
  </svg>
);
export default SvgGridStroke;
