import type { SVGProps } from "react";
import * as React from "react";

interface SvgGridStrokeProps extends SVGProps<SVGSVGElement> {
  color?: string;
}
const SvgInst = ({ color = "#1CAB9F", ...props }: SvgGridStrokeProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g stroke="currentColor" fill="currentColor" strokeWidth={0.1}>
      <path d="M12 2.775c3 0 3.36.011 4.546.065a6.2 6.2 0 0 1 2.09.388c.488.18.93.468 1.292.841.374.363.661.805.842 1.293.248.67.38 1.376.387 2.09.055 1.188.068 1.548.068 4.548s-.011 3.36-.065 4.546a6.2 6.2 0 0 1-.388 2.09 3.73 3.73 0 0 1-2.134 2.134 6.2 6.2 0 0 1-2.09.387c-1.186.055-1.542.066-4.546.066s-3.36-.011-4.546-.066a6.2 6.2 0 0 1-2.089-.387 3.5 3.5 0 0 1-1.293-.842 3.5 3.5 0 0 1-.841-1.293 6.2 6.2 0 0 1-.388-2.09c-.057-1.185-.07-1.541-.07-4.545s.011-3.36.065-4.546a6.2 6.2 0 0 1 .388-2.09c.181-.488.47-.93.844-1.292a3.5 3.5 0 0 1 1.293-.842 6.2 6.2 0 0 1 2.09-.388C8.64 2.789 9 2.775 12 2.775M12 .75c-3.056 0-3.439.013-4.638.068a8.3 8.3 0 0 0-2.732.525c-.75.282-1.43.724-1.991 1.296A5.5 5.5 0 0 0 1.34 4.63a8.3 8.3 0 0 0-.525 2.732C.763 8.562.75 8.944.75 12s.013 3.439.068 4.638c.02.934.197 1.857.525 2.731.282.75.726 1.43 1.298 1.992a5.5 5.5 0 0 0 1.992 1.299 8.3 8.3 0 0 0 2.731.525c1.2.055 1.583.067 4.638.067 3.056 0 3.439-.012 4.638-.067a8.3 8.3 0 0 0 2.732-.525 5.76 5.76 0 0 0 3.29-3.29 8.3 8.3 0 0 0 .525-2.732c.055-1.2.067-1.583.067-4.638 0-3.056-.012-3.439-.067-4.638a8.3 8.3 0 0 0-.525-2.732 5.5 5.5 0 0 0-1.298-1.991 5.5 5.5 0 0 0-1.992-1.299A8.3 8.3 0 0 0 16.64.815C15.44.763 15.055.75 12 .75" />
      <path d="M12 6.225a5.775 5.775 0 1 0 0 11.55 5.775 5.775 0 0 0 0-11.55m0 9.525a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5M18.005 7.345a1.35 1.35 0 1 0 0-2.7 1.35 1.35 0 0 0 0 2.7" />
    </g>
  </svg>
);
export default SvgInst;
