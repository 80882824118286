import React from "react";
import styled from "styled-components";

interface Props {
    className: any;
}

const StyledUserStroke = styled.svg`
  .path {
    fill: black;
    fill-rule: evenodd;
  }
`;

const UserStrokeIcon = ({ className }: Props): JSX.Element => {
    return (
        <StyledUserStroke
            className={`user-stroke-2 ${className}`}
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="path"
                clipRule="evenodd"
                d="M6.34315 14.3431C7.84344 12.8429 9.87827 12 12 12C14.1217 12 16.1566 12.8429 17.6569 14.3431C19.1571 15.8434 20 17.8783 20 20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20C18 18.4087 17.3679 16.8826 16.2426 15.7574C15.1174 14.6321 13.5913 14 12 14C10.4087 14 8.88258 14.6321 7.75736 15.7574C6.63214 16.8826 6 18.4087 6 20C6 20.5523 5.55228 21 5 21C4.44772 21 4 20.5523 4 20C4 17.8783 4.84285 15.8434 6.34315 14.3431Z"
                fill="black"
                fillRule="evenodd"
            />
            <path
                className="path"
                clipRule="evenodd"
                d="M12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4ZM6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8C18 11.3137 15.3137 14 12 14C8.68629 14 6 11.3137 6 8Z"
                fill="black"
                fillRule="evenodd"
            />
        </StyledUserStroke>
    );
};

export default UserStrokeIcon;
