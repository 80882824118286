import React from "react";
import styled from "styled-components";

const StyledChevronRightStroke = styled.svg`
  & .path {
    clip-rule: evenodd;
    fill: ${(props) => props.color};
    fill-rule: evenodd;
  }
`;

interface ChevronRightStrokeProps {
  color?: string;
}

export const ChevronRightStroke: React.FC<ChevronRightStrokeProps> = ({ color = "#00998F" }): JSX.Element => {
  return (
    <StyledChevronRightStroke
      className={`chevron-right-stroke`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M5.52851 3.52876C5.78886 3.26841 6.21097 3.26841 6.47132 3.52876L10.4713 7.52876C10.7317 7.78911 10.7317 8.21122 10.4713 8.47157L6.47132 12.4716C6.21097 12.7319 5.78886 12.7319 5.52851 12.4716C5.26816 12.2112 5.26816 11.7891 5.52851 11.5288L9.05711 8.00016L5.52851 4.47157C5.26816 4.21122 5.26816 3.78911 5.52851 3.52876Z"
        fill={"#00998F"}
        fillRule="evenodd"
      />
    </StyledChevronRightStroke>
  );
};

export default ChevronRightStroke;
