import type { SVGProps } from "react";
import * as React from "react";

const SvgJamWorldStroke = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.1}
    >
      <path
        fill="#000"
        d="M4.252 10a8.047 8.047 0 0 0 0 4H7.1a20 20 0 0 1 0-4zm.818-2h2.346c.266-1.217.65-2.307 1.121-3.214A8.04 8.04 0 0 0 5.07 8m14.678 2H16.9c.135 1.33.135 2.67 0 4h2.848a8.05 8.05 0 0 0 0-4m-.818-2a8.04 8.04 0 0 0-3.467-3.214c.472.907.855 1.997 1.121 3.214zm-9.818 2a17.8 17.8 0 0 0 0 4h5.776c.15-1.33.15-2.67 0-4zm.358-2h5.06a10.8 10.8 0 0 0-.783-2.177C13.119 4.568 12.447 4 12 4s-1.119.568-1.747 1.823C9.938 6.455 9.673 7.19 9.47 8m-4.4 8a8.04 8.04 0 0 0 3.467 3.214c-.472-.907-.855-1.997-1.121-3.214zm13.86 0h-2.346c-.266 1.217-.65 2.307-1.121 3.214A8.04 8.04 0 0 0 18.93 16m-9.46 0c.203.81.468 1.545.783 2.177C10.881 19.432 11.553 20 12 20s1.119-.568 1.747-1.823c.315-.632.58-1.367.783-2.177zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10"
      />
    </g>
  </svg>
);
export default SvgJamWorldStroke;
