import * as React from "react";
import type {SVGProps} from "react";

const SvgLogoutStroke = (props: SVGProps<SVGSVGElement>) => (
    <svg
        className="icon"
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <g fill="#C94A4C" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M15.293 6.293a1 1 0 0 1 1.414 0l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414-1.414L19.586 12l-4.293-4.293a1 1 0 0 1 0-1.414"/>
            <path
                d="M4.293 4.293A1 1 0 0 1 5 4h4a1 1 0 0 0 0-2H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h4a1 1 0 1 0 0-2H5a1 1 0 0 1-1-1V5a1 1 0 0 1 .293-.707M9 11a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2z"/>
        </g>
    </svg>
);
export default SvgLogoutStroke;
