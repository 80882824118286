import type { SVGProps } from "react";
import * as React from "react";

const SvgSunStroke = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M12 1a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1m0 7a4 4 0 1 0 0 8 4 4 0 0 0 0-8m-6 4a6 6 0 1 1 12 0 6 6 0 0 1-12 0m7 8a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0zM4.293 4.293a1 1 0 0 1 1.414 0l1 1a1 1 0 0 1-1.414 1.414l-1-1a1 1 0 0 1 0-1.414m15.414 14-1-1a1 1 0 0 0-1.414 1.414l1 1a1 1 0 0 0 1.414-1.414M1 12a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1m19-1a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zM6.707 17.293a1 1 0 0 1 0 1.414l-1 1a1 1 0 0 1-1.414-1.414l1-1a1 1 0 0 1 1.414 0m13-11.586a1 1 0 0 0-1.414-1.414l-1 1a1 1 0 0 0 1.414 1.414z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
export default SvgSunStroke;
