import * as React from "react";
import type { SVGProps } from "react";
const SvgSidebarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={33}
    fill="white"
    {...props}
  >
    <g
      stroke={"black"}
      fill={"white"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.1}
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M25.333 4.436H6.667A2.667 2.667 0 0 0 4 7.102V25.77a2.667 2.667 0 0 0 2.667 2.667h18.666A2.667 2.667 0 0 0 28 25.769V7.102a2.667 2.667 0 0 0-2.667-2.666M12 4.436v24"
      />
    </g>
  </svg>
);
export default SvgSidebarIcon;
