import * as React from "react";
import type { SVGProps } from "react";
const SvgEventPicturePlaceholder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={181}
    height={184}
    fill="none"
    {...props}
  >
    <path fill="#EEE" d="M0 8a8 8 0 0 1 8-8h173v184H8a8 8 0 0 1-8-8z" />
    <path
      fill="#fff"
      d="M116.352 108.333a4.67 4.67 0 0 1-4.668 4.667H69.668A4.67 4.67 0 0 1 65 108.333V82.667A4.666 4.666 0 0 1 69.668 78h9.337l4.669-7h14.005l4.668 7h9.337a4.67 4.67 0 0 1 4.668 4.667z"
    />
    <path
      fill="#EEE"
      d="M90.91 103.667c5.157 0 9.337-4.179 9.337-9.334S96.067 85 90.91 85c-5.156 0-9.337 4.179-9.337 9.333 0 5.155 4.18 9.334 9.337 9.334"
    />
  </svg>
);
export default SvgEventPicturePlaceholder;
