import type { SVGProps } from "react";
import * as React from "react";

const SvgFolderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={183}
    height={184}
    fill="none"
    {...props}
    viewBox={"0 0 183 184"}
    style={{ flexShrink: 0 }}
  >
    <path fill="#EEE" d="M0 8.436a8 8 0 0 1 8-8h175v184H8a8 8 0 0 1-8-8z" />
    <path
      fill="#DEDEDE"
      d="M123.614 79.924a4 4 0 0 0-4-4H87.527a4 4 0 0 1-3.241-1.656l-4.092-5.66a4 4 0 0 0-3.242-1.657H63.388a4 4 0 0 0-4 4v41.489a4 4 0 0 0 4 4h56.227a4 4 0 0 0 4-4z"
    />
    <path fill="#fff" d="M64.072 72.342h53.052v24.827H64.072z" />
    <path
      fill="#CDCDCD"
      d="M59.387 81.4a4 4 0 0 1 4-4h32.087a4 4 0 0 0 3.241-1.656l4.092-5.66a4 4 0 0 1 3.241-1.656h13.566a4 4 0 0 1 4 4v41.488a4 4 0 0 1-4 4H63.387a4 4 0 0 1-4-4z"
    />
  </svg>
);
export default SvgFolderIcon;
