import type { SVGProps } from "react";
import * as React from "react";

interface SvgGridStrokeProps extends SVGProps<SVGSVGElement> {
  color?: string;
}
const SvgSideBarStroke = ({ color = "#000", ...props }: SvgGridStrokeProps) => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20" />
      <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2" />
    </g>
  </svg>
);
export default SvgSideBarStroke;
