import * as React from "react";
import type {SVGProps} from "react";

const SvgChevronStroke = (props: SVGProps<SVGSVGElement>) => (
    <svg
        className="icon"
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <path
            fill="#888"
            fillRule="evenodd"
            d="M7.529 5.529c.26-.26.682-.26.942 0l4 4a.667.667 0 1 1-.943.943L8 6.943 4.47 10.472a.667.667 0 0 1-.942-.943z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgChevronStroke;
