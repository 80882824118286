import type { SVGProps } from "react";
import * as React from "react";

interface SvgGridStrokeProps extends SVGProps<SVGSVGElement> {
  color?: string;
}
const SvgCalendarStroke = ({
  color = "black",
  ...props
}: SvgGridStrokeProps) => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g stroke={color} strokeWidth={0.1} fillRule="evenodd" clipRule="evenodd">
      <path
        fill={color}
        d="M17 2a1 1 0 1 0-2 0v1H9V2a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3h-2zm3 7V6a1 1 0 0 0-1-1h-2v1a1 1 0 1 1-2 0V5H9v1a1 1 0 0 1-2 0V5H5a1 1 0 0 0-1 1v3zM4 11h16v9a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1z"
      />
    </g>
  </svg>
);
export default SvgCalendarStroke;
