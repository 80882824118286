import type { SVGProps } from "react";
import * as React from "react";

const SvgMenuStroke = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g fill="#888" fillRule="evenodd" clipRule="evenodd">
      <path d="M1.333 8.5c0-.368.299-.667.667-.667h12a.667.667 0 1 1 0 1.334H2a.667.667 0 0 1-.667-.667M1.333 4.5c0-.368.299-.667.667-.667h12a.667.667 0 1 1 0 1.334H2a.667.667 0 0 1-.667-.667M1.333 12.5c0-.368.299-.666.667-.666h12a.667.667 0 0 1 0 1.333H2a.667.667 0 0 1-.667-.667" />
    </g>
  </svg>
);
export default SvgMenuStroke;
